export const hcpIIR: any = {
  hcpMainMenu: "Main Navigational Menu HCP GCSO - Retina Spain",
  hcpBannerHTML: "Banner - HTML - ES - HCP - Retina Spain",
  hcpFooter: "Footer HCP - Retina Spain",
  hcpExitPopup: "Exit Popup - HCP - Retina Spain",
  hcpHCPSwitcher: "HCP Switcher Modal Pop Up - Retina Spain",
  hcpTopNav: "Top Navigational Menu HCP - Retina Spain",
  hcpSiteLogo: "Site logos - Retina Spain",
  hcpFootRef: "Reference - ES - HCP - Retina Spain",
  hcpNavCalloutPara: "Footer Callout - ES - HCP - Retina Spain",
  hcpTestOrRetestPara: "Test or Retest callout section - ES - HCP - Retina Spain",
  sciAdv: "Science is advancing - ES - HCP - Retina Spain",
  hcpEvolutionGTPara: "Evolution of genetic testing - ES - HCP - Retina Spain",
  timeLine: "DNA with Historical Data HTML - ES - HCP - Retina Spain",
  breakthrough: "Breakthrough science - ES - HCP - Retina Spain",
  backToTopGTM: "back to top - emerging science - hcp",
  footerClassName: "investigating-irds-footer"
}

import React from 'react'
import { graphql } from 'gatsby'
import { HTMLRenderer, Layout, PageWrapper, HcpMobileBanner, HcpClinicalTrials } from '@retina-packages/retina-theme-bootstrap';
import  MetaTagContainer from '@retina-packages/retina-theme-bootstrap/packages/containers/MetaTagContainer';
import { Props, PageQueryResult } from '@retina-packages/retina-theme-bootstrap/packages/types/CommonTypes'
import { exitPopDataType } from '@retina-packages/retina-theme-bootstrap/packages/types/ExitPopUpType';
import reusableDataBuilders from '@retina-packages/retina-theme-bootstrap/packages/helpers/reusableDataBuilders';
import { ClinicalTrialsProvider } from '@retina-packages/retina-theme-bootstrap/packages/components/DNAComponent';
import { CalloutWithHeaderContainer, CalloutWithHeaderWrapper, GeneTestEvolutionContainerWrapper, HcpEmergingScienceWrapper } from './styles'
import { hcpIIR } from './constants';
import { MobileBanner } from '@retina-packages/retina-theme-bootstrap/packages/components/styles/styles';
import { deriveMultiLogo, deriveSiteMenus, footRefHTMLBuilder, fullHTMLDataBuilder } from '../../../utils';
import retinaConfig from '../../../utils/retinaConfigs';
import { EsHeaderContainer } from '../../../css/common/style';
import { ContainerWrapper, TestorRetestPatientSectionWrapper } from '../../patient/GTB/styles';
import { HcpFooterBlockWrapper } from '../home/styles';

const GeneticTestingBenefitTemplate = (props: Props) => {
  const pgProp: PageQueryResult = props.data
  const blocks = pgProp.nodePage.relationships.field_paragraphs_gcso

  const pgContextsIIR = props.pageContext
  const htmlStaticFilesIIR = {
    nonSVGImages: pgContextsIIR.nonSVGImages,
    svgMediaImages: pgContextsIIR.svgMediaImages,
    allMediaDocument: pgContextsIIR.documents
  }

  const { mainMenu, footerMenu } = deriveSiteMenus({ pgProp, siteMainMenu: hcpIIR.hcpMainMenu, siteFooterMenu: hcpIIR.hcpHomeFooterMenu, addHCPPrefix: retinaConfig.addHcpPrefix });
  const audience = pgProp.nodePage.relationships.field_general_tags_gcso[0].name

  const bannerTitle = hcpIIR.hcpBanner
  const carouselTitle = hcpIIR.hcpCarousel
  const footerTitle = hcpIIR.hcpFooter
  const exitPopupTitle = hcpIIR.hcpExitPopup
  const hcpSwitcherTitle = hcpIIR.hcpHCPSwitcher
  const topNavTitle = hcpIIR.hcpTopNav
  const {
    footerText,
    exitData,
    hcpSwitcher,
    topNavs
  }: {
    footerText: string;
    exitData: exitPopDataType;
    hcpSwitcher: exitPopDataType;
    topNavs: string;
  } = reusableDataBuilders({blocks, bannerTitle, carouselTitle, audience, footerTitle, exitPopupTitle, hcpSwitcherTitle, topNavTitle})

  const bannerHTML = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpBannerHTML })
  const hcpClinicalTrialReference = footRefHTMLBuilder({ blocks, title: hcpIIR.hcpFootRef })
  const siteLogos: any = deriveMultiLogo({ blocks, title: hcpIIR.hcpSiteLogo })
  const footerCalloutHTML = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpNavCalloutPara });
  const testOrRetestHTML = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpTestOrRetestPara });
  const evoGeneTestHTML = fullHTMLDataBuilder({ blocks, title: hcpIIR.hcpEvolutionGTPara });
  const sciAdvHTML = fullHTMLDataBuilder({ blocks, title: hcpIIR.sciAdv });

  const breakthroughData = fullHTMLDataBuilder({ blocks, title: hcpIIR.breakthrough });
  const timelineData = blocks.filter((inf: any) => inf.relationships.field_reusable_paragraph.label === hcpIIR.timeLine).map(
    (info: any) => info.relationships.field_reusable_paragraph.relationships.paragraphs
  ).at(0);
  const timelineHTML = timelineData?.field_para_text_gcso.value
  const dnaBackImage: string = timelineData?.relationships?.field_para_media_gcso[0]?.relationships?.field_media_image?.localFile?.publicURL

  return (
    <div className='hcppage'>
      <HcpClinicalTrials className="hcpEmergingScience">
       <EsHeaderContainer>
       <HcpEmergingScienceWrapper className='br-hcp-emerging-wrapper'>
        <HcpMobileBanner className="hcpmobilewrapper">
          {props.pageContext !== null && props.pageContext.metaInfo !== null && (
            <MetaTagContainer metaData={props.pageContext.metaInfo} />
          )}
          <Layout
            title={"siteTitle"}
            location={props.location}
            modalCloseButtonLabel={retinaConfig.modalCloseButtonLabel}
            data={mainMenu}
            mdata={footerMenu}
            footerData={footerText}
            audience={audience}
            exitPopData={exitData}
            footref={hcpClinicalTrialReference}
            hcplinks={hcpSwitcher}
            topNavigation={topNavs}
            siteLogos={siteLogos}
            backToTopContent={retinaConfig.backToTopContent}
            gtmBackToTopLabel={hcpIIR.backToTopGTM}
            staticMediaFiles={htmlStaticFilesIIR}
            showRefContent={retinaConfig.showRefContent}
            hideRefContent={retinaConfig.hideRefContent}
            hcpValidate={retinaConfig.hcpValidate}
            preIndexUrl={retinaConfig.preIndexUrl}
            hcpHomeUrl={retinaConfig.hcpHomeUrl}
            hcpPrefix={retinaConfig.hcpPrefix}
            siteHomeUrl={retinaConfig.siteHomeUrl}
            footerClassName={hcpIIR.footerClassName}
            cpraFooter={true}
            cpraFooterEnv={process.env.CPRA_FOOTER_ENV}
            cpraOT={true}
            cpraOTEnv={process.env.OT_ENV}
            cpraOTuuid={process.env.OT_UUID}
            {... {...retinaConfig.layoutAttrs.hcpAttrs, ...retinaConfig.layoutAttrs.commonAttrs}}
          >
            <PageWrapper className='pagewrapper pagewrapper-clinical-trial hcpEmergingScienceWrapper'>
              {/* Banner section */}
              <MobileBanner className="mobile-banner clinical-trials-banner">
                <HTMLRenderer data={htmlStaticFilesIIR} html={bannerHTML} tagName='section' className='internal-banner o-column--full' simpleImage={true} />
              </MobileBanner>
              <CalloutWithHeaderContainer className='main-section o-top-space emerging-science-intro'>
                <CalloutWithHeaderWrapper className='o-container'>
                    <HTMLRenderer html={sciAdvHTML} data={htmlStaticFilesIIR} tagName='div' className='o-text-center' />
                </CalloutWithHeaderWrapper>
                <GeneTestEvolutionContainerWrapper className='c-family--story evolution-genetic-test'>
                  <HTMLRenderer html={evoGeneTestHTML} data={htmlStaticFilesIIR} tagName='div' className='o-container' />
                </GeneTestEvolutionContainerWrapper>
                {timelineHTML && (
                  <ClinicalTrialsProvider html={timelineHTML} staticMedia={htmlStaticFilesIIR} backgroundImage={dnaBackImage}/>
                )}
                <ContainerWrapper className='containercliniwrap'>
                  <HTMLRenderer data={htmlStaticFilesIIR} html={breakthroughData}/>
                  <TestorRetestPatientSectionWrapper className='hcpretest-wrapper'>
                    <HTMLRenderer html={testOrRetestHTML} data={htmlStaticFilesIIR} className='o-container' tagName='div' />
                  </TestorRetestPatientSectionWrapper>
                  <HcpFooterBlockWrapper className='o-container hcp-footer-wrap arrow-footer'>
                    <div className='footer-block o-top-space emerging-science--footer'>
                      <HTMLRenderer html={footerCalloutHTML} data={htmlStaticFilesIIR} tagName='div' className='footer-block--row' />
                    </div>
                  </HcpFooterBlockWrapper>
                </ContainerWrapper>
              </CalloutWithHeaderContainer>
            </PageWrapper>
          </Layout>
        </HcpMobileBanner>
        </HcpEmergingScienceWrapper>
        </EsHeaderContainer>
      </HcpClinicalTrials>
    </div>
  )
}

export default GeneticTestingBenefitTemplate

export const pageQuery = graphql`
  query($id: Int!) {
    nodePage(drupal_internal__nid: {eq: $id}) {
      relationships {
        ...HcpIIRPageQuery
      }
    }
  }
`
